import {useState,useEffect,useRef} from 'react';

import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove,listclient } from "./AvoirsSlice";
import ModalAjouter from './ModalAjouter';
import ModalModifer from './ModalModifier';

export default function Avoirs() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [numAvoir,setNumAvoir] =useState('')
    const [Chargement, setChargement] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [rechercheEtat,setRchercheEtat] = useState('tout');
    const [idCurrentclient,SetidCurrentclient] = useState('');

    const id = useSelector(state => state.userData.id);
    const avoirData = useSelector(state => state.avoirsData);
    const modifSuccess=avoirData.modifOk;
    const ajoutSuccess=avoirData.ajoutOk;
    const listAvoirs = avoirData.list;

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      };

    const rechercheField=useRef('')
    const etatField=useRef('')

    const dispatch= useDispatch();

    const GetClients=async () => {
        try {
        const response = await axios.post(`${apiUrl}clients/${id}`);

        const clientsData = response.data.map(client => ({
            value: client._id,
            label: client.designation,
          }));
          dispatch(listclient(clientsData));
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

    const getAvoirs=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}avoirs/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const Ajouter = async () =>{
        try {
            const response = await axios.post(`${apiUrl}lastavoir/${id}`);
            if(response.data.status==="ok")

                setNumAvoir(response.data.valeur)
                dispatch(showmodal(true));
        }
        catch (error) {
            console.log(error)
        }
    }

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listAvoirs.find(avoir => avoir._id === id);
        const idclient = valeur.client.map(client => client.id);
        const designation = valeur.client.map(client => client.designation);
        const currentOption ={value :idclient[0],label : designation[0]}
        SetidCurrentclient({currentOption});
        dispatch(current(valeur));
    };

    const suppression = async (idavoir)=> {
        setChargement(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/avoir/${id}/${idavoir}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idavoir));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
        setChargement(false)
    }

    const supprimer = (idavoir) => {

        let result = window.confirm(`Supprimer cet avoir ?`);
        if (result === true) {
            suppression(idavoir);
        }
    }

    function ShowList() {
        HideSuccessMsg();
        if(listAvoirs.length > 0){
        let listFiltre=listAvoirs;
        if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(avoir => avoir.client.some(client => client.designation.toLowerCase().includes(rechercheInput.toLowerCase())))
        }

        if (rechercheEtat !=="tout"){
            listFiltre = listFiltre.filter(avoir => avoir.etat.startsWith(rechercheEtat)) ;               
        }
        
        return listFiltre.map(Avoir => {
            let StyleColor = "#99FBA9"
            if(Avoir.etat==="Enregistré"){
              StyleColor = "#FBD399"
            }
            return(
                <tr key={Avoir._id}>
                <td>{Avoir.numero?.toString().padStart(4, '0')}</td>
                {Avoir.client.map(leclient => (
                <td>{leclient.designation}</td>
                ))}
                <td>{Avoir.montant}</td>
                <td>{Avoir.remarque}</td>
                <td style={{color:StyleColor}}>{Avoir.etat}</td>
                <td>{Avoir.date!=="" ? formatDateToFrench(Avoir.date) : ''}</td>
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Avoir._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Avoir._id)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={7} align='center'>Pas de données</td>
        </tr>;
    }
    }

    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const etat=etatField.current.value
        if(recherche!==rechercheInput){
            setRchercheInput(recherche)
             }
             if(etat!==rechercheEtat){
                setRchercheEtat(etat)
            }
    }

    useEffect(() =>{
        getAvoirs();
        GetClients();
      },[]);
    return (
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Avoirs</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un avoir
    </button>
  </div>

  <div className="d-flex justify-content-start mb-3 w-50" >
        
  <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche client" ref={rechercheField} onKeyUp={Recherche} ></input>

  <select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={etatField} onChange={Recherche}>
            <option value="tout">Etat</option>
            <option value="Enregistré">Enregistré</option>
            <option value="Remboursé">Remboursé</option>
            <option value="Appliqué">Appliqué</option>

</select>
    </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Avoir ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Avoir modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Avoir supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Numéro</th>
                <th scope="col">Client</th>
                <th scope="col">Montant</th>
                <th scope="col">Remarque</th>
                <th scope="col">Etat</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
<ModalAjouter NumeroAvoir={numAvoir}/>
<ModalModifer CurrentIdClient={idCurrentclient}/>
    </div>
  )
}
