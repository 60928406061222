import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ajoutsuccess,showmodal,modifsuccess,listclient,getList,idfacture,showapercu } from "./FactureSlice";
import Impressiongroupe from './Impressiongroupe';
import ModalApercu from './ModalApercu';

export default function Factures() {
    const Facturedata = useSelector(state => state.facturesData);
    const ajoutSuccess = Facturedata.ajoutsuccess;
    const modifSuccess = Facturedata.modifsuccess;
    const listFactures = Facturedata.list;
    const id = useSelector(state => state.userData.id);

    const [Chargement, setChargement] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheFactureInput,setrechercheFactureInput] = useState('');
    const [rechercheReglementInput,setrechercheReglementInput] = useState('tout');
    const [rechercheLivraisonInput,setrechercheLivraisonInput] = useState('tout');

    const dispatch= useDispatch();
    const navigate = useNavigate();

    const rechercheField=useRef('');
    const rechercheFactureField=useRef('');
    const rechercheReglementField=useRef('');
    const rechercheLivraisonField=useRef('');

    const currentAvoir=useRef('');
    const currentTotal=useRef('');
    let listAchats=useRef([]);


    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

    const apiUrl = process.env.REACT_APP_API_URL;
    const Ajouter=() => {
        navigate('/home/nouvellefacture');
        }

        const HideSuccessMsg = () => {
            setTimeout(() => {
                dispatch(ajoutsuccess(false));
                dispatch(modifsuccess(false));
                setRemoveSuccess(false);
        
            }, 5000);
        }   

        const GetClients=async () => {
            try {
            const response = await axios.post(`${apiUrl}clients/${id}`);
              dispatch(listclient(response.data));
            }
         catch (error) {
            console.error('Error:', error);
        }
        }

        const getFactures=async () => {
            setChargement(true);
            try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}factures/${id}`);
            dispatch(getList(response.data));
            }
         catch (error) {
            console.error('Error:', error);    
        }
        setChargement(false);
        }
        const GetFacture = async (idfacture,facture,date) =>{
            setChargement(true);
            try {
                const dateFr=formatDateToFrench(date)
                const response = await axios.get(`${apiUrl}generer/facture/${id}/${idfacture}`,{
                  responseType: 'blob',
                });
            
                // Créez un lien pour télécharger le PDF
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',`Facture N${facture} ${dateFr}.pdf`);
                document.body.appendChild(link);
                link.click();
            }
                catch (error) {
                    console.error('Error generating invoice:', error);
                  }
                  setChargement(false);
        }
    
        const suppression = async (idfacture)=> {

            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${apiUrl}delete/facture/${id}/${idfacture}`);
                if(response.data.status==="ok")
                    {
                        const newlist=listFactures.filter(facture => facture._id !== idfacture);
                        dispatch(getList(newlist));
                        setRemoveSuccess(true);
                        HideSuccessMsg();
                    }
            } catch (error) {
                console.log(error)
            }
        }

        const supprimer = (id,numero) => {
            let result = window.confirm(`Supprimer la facture N° ${numero} ?`);
            if (result === true) {
                suppression(id);
            }
        }
    
        function ShowList() {
            HideSuccessMsg();
            let StyleColor = "#99FBA9";
            let StyleColor2 = "#99FBA9";
            let listFiltre=listFactures;
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(facture => facture.client.some(client => client.designation.includes(rechercheInput)))
            }
            if(rechercheFactureInput > 0)
                {
                    listFiltre = listFiltre.filter(facture => facture.facture===rechercheFactureInput)                   
                }
            if(rechercheReglementInput !=="tout")
                {
                    listFiltre = listFiltre.filter(facture => facture.etat===rechercheReglementInput)                   
                }
            if(rechercheLivraisonInput !=="tout")
                {
                    listFiltre = listFiltre.filter(facture => facture.livraison===rechercheLivraisonInput)                   
                }
            return listFiltre.map(facture => {
                if(facture.etat!=="Réglée"){
                    StyleColor = "#FBD399"
                  }
                  if(facture.livraison!=="Livrée"){
                    StyleColor2 = "#FBD399"
                  }
            return(
                <tr key={facture._id}>
                {facture.client.map(leclient => (
                <td style={{ maxWidth: "270px" }}>{leclient.designation}</td>
                ))}
                <td>{facture.facture?.toString().padStart(5, '0')}</td>
                <td>{facture.total} Dhs</td>
                <td>{facture.reglement}</td>
                <td style={{color:StyleColor}}>{facture.etat}</td>
                <td style={{color:StyleColor2}}>{facture.livraison}</td>
                <td>{facture.date!=="" ? formatDateToFrench(facture.date) : ''}</td>
                <td>
                <button type="button" class="btn btn-success me-4" onClick={() => ShowFacture(facture._id,facture.total_brute,facture.avoir)}>Aperçu</button>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(facture._id)}>Modifier</button>
                <button type="button" class="btn btn-info me-4" onClick={() => GetFacture(facture._id,facture.facture,facture.date)}>Imprimer</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(facture._id,facture.facture)}>Supprimer</button>
                </td>

                </tr>
            )
            }
            )
        }
        const Recherche=(event) =>{
            const recherche=rechercheField.current.value
            const rechercheFacture=rechercheFactureField.current.value
            const rechercheReglement = rechercheReglementField.current.value
            const rechercheLivraison = rechercheLivraisonField.current.value

            if(recherche!==rechercheInput){
            setRchercheInput(recherche)
             }
            if(rechercheFacture!==rechercheFactureInput){
                setrechercheFactureInput(rechercheFacture)
                 }
            if(rechercheReglement!==rechercheReglementInput){
                setrechercheReglementInput(rechercheReglement)
                }
            if(rechercheLivraison!==rechercheLivraisonInput){
                setrechercheLivraisonInput(rechercheLivraison)
                }
        }
        const ShowFacture = async (idfacture,total,avoir) => {
            try {
            currentAvoir.current = {
                "id" : avoir["0"].id,
                "numero" : avoir["0"].numero,
                "montant" : avoir["0"].montant,

            }   
        } 
        catch (error) {
            currentAvoir.current=''
        }     
            currentTotal.current = total
            setChargement(true);
            try {

                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${apiUrl}list/facture/${id}/${idfacture}`);
                   response.data.produits.map(product => {
                    const _id=product._id
                    const designation=product.designation
                    const stock=product.stock
                    const prix_vente=product.prix_vente;
                    const prix_achat=product.prix_achat;
                    const tva=product.tva;
                    
                    const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                    if (existingProductIndex === -1) {
                        const valeurs={_id,designation,stock,prix_vente,prix_achat,tva};
                    listAchats.current.push({...valeurs});
                    }
        
                });   
                dispatch(showapercu(true)) 
            }
             catch (error) {
                console.error('Error:', error);    
            }
            setChargement(false);
        
        }

        const modifier = (id) => {
            const filtreachat = listFactures.filter(list => list._id === id);
            dispatch(idfacture(filtreachat));
            navigate('/home/editfacture');
          }

          const ImpressionGroupe = () => dispatch(showmodal(true));
        useEffect(() =>{
            getFactures();
            GetClients();
            HideSuccessMsg();
          },[])
    return (
        <>
        <div className="container-lg align-items-center">
    <section className="mt-4">
        <p className="h2 text-center">Factures</p>
    
        {Chargement? 
                    <div d-flex className="d-flex justify-content-center">
                    <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  </div>
                :
                <div style={{ height: '3rem' }}></div>
                }
    
        <div className="d-flex justify-content-end mb-3">
            
        <button className="btn btn-primary mr-2" onClick={Ajouter}>
          <i className="fas fa-user-plus"></i> Nouvelle facture
        </button>

        <button className="btn btn-secondary ms-2" onClick={ImpressionGroupe}>
          <i className="fas fa-user-plus"></i> Impression groupée
        </button>
      </div>

      <div className="d-flex justify-content-start mb-3 w-75" >
      <input type="text" className="form-control " id="client"  placeholder="Recherche client" ref={rechercheField} onKeyUp={Recherche} ></input>
      <input type="text" className="form-control ms-2" id="facture"  placeholder="Recherche facture" ref={rechercheFactureField} onKeyUp={Recherche} ></input>
      <select className='form-select form-select-sm ms-2' id="Réglement" ref={rechercheReglementField} onChange={Recherche}>
        <option value="tout">Etat réglement</option>
        <option value="Réglée">Réglée</option>
        <option value="Non Réglée">Non Réglée</option>
      </select>
      <select className='form-select form-select-sm ms-2' id="livraison" ref={rechercheLivraisonField} onChange={Recherche}>
        <option value="tout">Etat livraison</option>
        <option value="Livrée">Livrée</option>
        <option value="Non Livrée">Non Livrée</option>
      </select>
      </div>
    
      {ajoutSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Facture ajoutée avec succès</strong>
            </div>                 
                :
                ''                    
            }
    
    {modifSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Facture modifiée avec succès</strong>
            </div>                 
                :
                ''                    
            }
    
    {removeSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Facture supprimée avec succès</strong>
            </div>                 
                :
                ''                    
            }
    
    {/* <div className="d-flex justify-content-start mb-3 w-25" >
      
      <select className="form-select" id="fournisseur" value={rechercheFournisseur} ref={fournisseurField} onChange={Recherche}>
            <option value="tout">Fournisseur</option>
                <Listfournisseurs/>
                </select>    
    
        </div> */}
        <div class="table-responsive">
      <table className="table  align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Client</th>
                    <th scope="col">N° facture</th>
                    <th scope="col">Total TTC</th> 
                    <th scope="col">Méthode</th>
                    <th scope="col">Régelement</th>
                    <th scope="col">Livraison</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <ShowList/>
            </tbody>
        </table>
        </div>
    </section>
        </div>
        <Impressiongroupe/>
        <ModalApercu Achats ={listAchats} Total={currentTotal.current} Avoir={currentAvoir}/>

    </>
        
      )
    
}
