import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {showapercu,ajoutsuccess} from './EchantillonSlice'
import Catlist from '../produits/Catlist';
import EchantillonRetour from './ApercuEchantillon';


export default function NouvelEchantillon() {
    const [Chargement, setChargement] = useState(false);
    const [etat,setEtat] =useState('');
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheCat,setRchercheCat] = useState('tout');
    const [listProduits, setListProduits] = useState([]);

    const id = useSelector(state => state.userData.id);
    const apiUrl = process.env.REACT_APP_API_URL;

    const dispatch= useDispatch();
    const navigate = useNavigate();

    const rechercheField=useRef('');
    const catField=useRef('');
    let listAchats=useRef([]);
    const remarqueField= useRef('');

    const StockUpdate = (event) => {
        const qte = event.target.value;
        const id = event.target.id;
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.stock = qte;
                const _id=product._id;
                const designation=product.designation;
                const stock=product.stock;

                const valeurs={_id,designation,stock};
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock
                    listAchats.current[existingProductIndex].stock = stock;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };
            }
            return product;
        });
        
    };

    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const idcat=catField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
         if(idcat!==rechercheCat){
        setRchercheCat(idcat)
    }

    }

    function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
            }
            if (rechercheCat !=="tout"){
                listFiltre = listFiltre.filter(produit => 
                    produit.categorie.some(categorie => 
                      categorie.id===rechercheCat) 
                    )
                ;               
            }
        return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            if(currentQte){
                stock=currentQte.stock;
            }

            return(
                <tr key={Produit._id}>
                <td>{Produit.designation}</td>
                <td><input type="number" className="form-control-sm" id={Produit._id} defaultValue={stock}  onChange={StockUpdate} placeholder="Quantité"></input></td>
                <td>{Produit.stock}</td>
            </tr>                
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    }   

    const removeZeroStock = (list) => {
        return list.current.filter(item => item.stock !== "0");
      };

    const Ajouter = async () => {
        const remarque=remarqueField.current.value.trim()
        if(remarque===""){
            alert('veuillez saisir une remarque')
            return
        }
        const produits = removeZeroStock(listAchats)
        const date = new Date();
        date.setHours(23, 59, 59, 999);
        
        setEtat('');
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}new/echantillon/${id}`,{
                remarque,
                produits,
                date,
            });
                dispatch(ajoutsuccess(true));
                navigate('/home/stock/echantillon', { replace: true });
        }
     catch (error) {
        console.error('Error:', error);
        setEtat('erreur');    
    }
    setChargement(false);
    }  

    const getProduits=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}produits/${id}`);
        setListProduits(response.data);
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }
    
    const ShowRetour =() =>{
        dispatch(showapercu(true))
   }
    useEffect(() =>{
        getProduits();
      },[])
  return (
    <>
    <div className="container-lg align-items-center">
    {Chargement? 
                <div d-flex className="d-flex justify-content-center mb-2">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

        {etat==='erreur' ?
                <div class="alert alert-danger  mb-4 text-center" role="alert">
                <strong>Erreur de connexion</strong>
                </div>                 
                :
                ''                    
                }

        <p className="h2 text-center">Echantillon</p>

        <div className="d-flex justify-content-center mt-3 mb-5" >
        <input type="text" className="form-control w-25" id="remarque"  placeholder="Remarque" ref={remarqueField} ></input>

            </div>
        <div className="d-flex justify-content-start mb-3 w-50" >
        <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche produit" ref={rechercheField} onKeyUp={Recherche} ></input>

<select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} value={rechercheCat} onChange={Recherche}>
          <option value="tout">Catégories</option>
          <Catlist/>
</select>
</div>
<div class="table-responsive">
<table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Désignation</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Stock actuel</th>
                </tr>
            </thead>
            <tbody>
            <ShowList/>
            </tbody>
            </table>
            </div>
            <div className="d-flex justify-content-end mb-3">
            <button type="button" className="btn btn-primary" onClick={ShowRetour}>Aperçu</button>
            <button type="button" className="btn btn-success ms-3" onClick={Ajouter}>Enregistrer</button>
      </div>
      <EchantillonRetour Achats={listAchats}/>
    </div>
    </>
  )
}
